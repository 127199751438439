// @ts-strict-ignore
import clsx from 'clsx'

export const StickyTh = ({
  children,
  isFirstColumn = false,
  isCentered = false,
  className,
}: {
  children: React.ReactNode
  isFirstColumn?: boolean
  isCentered?: boolean
  className?: string
}) => (
  <th
    scope='col'
    className={clsx(
      'bg-uibg-1 sticky top-0 z-20 border-b border-gray-300 py-3.5 text-left text-sm font-semibold text-gray-900',
      !isCentered && 'text-left',
      isCentered && 'text-center',
      isFirstColumn && 'pl-4 pr-3 sm:pl-6',
      !isFirstColumn && 'px-3',
      className,
    )}
  >
    {children}
  </th>
)

export const Th = ({
  children,
  isFirstColumn = false,
  isCentered = false,
  className,
}: {
  children: React.ReactNode
  isFirstColumn?: boolean
  isCentered?: boolean
  className?: string
}) => (
  <th
    scope='col'
    className={clsx(
      'bg-uibg-1 border-b border-gray-300 py-3.5 text-left text-sm font-semibold text-gray-900',
      !isCentered && 'text-left',
      isCentered && 'text-center',
      isFirstColumn && 'pl-4 pr-3 sm:pl-6',
      !isFirstColumn && 'px-3',
      className,
    )}
  >
    {children}
  </th>
)

const Td = ({
  children,
  className,
  isFirstColumn = false,
}: {
  children: React.ReactNode
  className?: string
  isFirstColumn?: boolean
}) => (
  <td
    className={clsx(
      'whitespace-nowrap py-4 text-sm',
      !isFirstColumn && 'px-3 text-gray-500',
      isFirstColumn && 'pl-4 pr-3 font-medium text-gray-900 sm:pl-6',
      className,
    )}
  >
    {children}
  </td>
)

const RoundedTable = ({
  children,
  className,
  compact = false,
  ...rest
}: {
  children: React.ReactNode
  compact?: boolean
  className?: string
}) => (
  <div className={`border-uiBg-3 bg-uibg-1 overflow-x-auto border ${className}`}>
    <div className={`my-4 ${compact ? 'mt-0' : ''} overflow-x-auto shadow-sm`}>
      <table className='min-w-full table-auto divide-y divide-gray-300' {...rest}>
        {children}
      </table>
    </div>
  </div>
)

const Thead = ({ children }: { children: React.ReactNode }) => <thead className='bg-uibg-1'>{children}</thead>

const Tbody = ({ children }: { children: React.ReactNode }) => (
  <tbody className='divide-y divide-gray-200 bg-white'>{children}</tbody>
)

interface BasicTableProps {
  columnHeaders: React.ReactNode[]
  rows: React.ReactNode[][]
  className?: string
  compact?: boolean
}

export const BasicTable = ({ columnHeaders, rows, compact = false, className, ...rest }: BasicTableProps) => (
  <RoundedTable compact={compact} className={className} {...rest}>
    <Thead>
      <tr>
        {columnHeaders.map((header, index) => (
          <StickyTh key={`${header.toString()}${index}`} isFirstColumn={index === 0} className={compact ? '' : ''}>
            {header}
          </StickyTh>
        ))}
      </tr>
    </Thead>
    <Tbody>
      {rows.map((row, i) => (
        <tr key={i}>
          {row.map((cell, index) => (
            <Td key={index} isFirstColumn={index === 0} className={compact ? '!py-1' : ''}>
              {cell}
            </Td>
          ))}
        </tr>
      ))}
    </Tbody>
  </RoundedTable>
)
